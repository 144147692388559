const tab = {
  home:'ホーム',
  find:'発見',
  position:'ポジション',
  collect:'コレクション',
  my:'マイページ'
}
const index = {
  welcome:'ようこそ',
  TNkkrich:'TNkkrich',
  notice:'NFT技術の影響力 世界のデジタル資産政策 NFTニュース NFT取引注文の種類と手順',
  PreSale: 'プレセール中',
  TransactionList: '取引リスト',
  num:'数量',
  minPrice:'最低買戻し価格',
  nftDetail:'NFTの詳細',
  Countdown:'カウントダウン',
  nftInfo:'NFT情報',
  publisherInfo:'発行者情報',
  features:'作品の特性',
  desc:'作品の説明',
  prePrice: 'プレセール価格',
  RepurchasePrice:'買戻し価格',
  PurchaseType:'購入タイプ',
  oerderNow:'今すぐ注文',
  myCollect: 'マイコレクション',
  myCollect1: 'マイフラグメント',
  Synthesis: '合成',
  hold: '保有',
  Introduction:'紹介',
  ViewTheAuthor:'この作者を見る'
}

const find = {
  search:'検索',
  myCollect: 'マイコレクション',
  myCollect_desc:'トップNFTアート展示はすべてTNFTで',
  NFTsp: 'NFTフラグメント',
  NFTsp_desc: '保有しているNFTをフラグメントに分解',
  BecomeAmerchant: '発行者になる',
  BecomeAmerchant_desc:'発行者になると、自分のNFTを発行できます',
  Announcement_headlines: 'お知らせ',
  About_NFT: 'NFTについて',
  Creation_Center: 'クリエーションセンター',
  Synthesis_center: '合成センター',
  issure:'発行機関'
}

const position = {
  Mypositions:'マイポジション',
  PositionMarketValue: 'ポジションの時価（USDT）',
  TodayProfitAndLoss: '今日の損益',
  TotalProfitAndLoss: '総損益',
  HoldingCost: '保有コスト',
  Number_of_fragments: 'フラグメント数',
  PledgeShards: '質押フラグメント',
  PledgeIncome: '質押収入',
  TNFTRoyaltiesTrade:'TNFTロイヤリティ取引',
  QuickBuy: 'ワンクリック購入',
  QuickSell: 'ワンクリック売却',
  History:'履歴',
  name:'名前',
  price:'現在価格',
  Market:'市場価値',
  Pledge: '質押',
  TNFT: 'TNFT',
  BuyingPrice: '買い価格',
  SellingPrice: '売り価格',
  EstimatedBuybackPrice:'推定買戻し価格',
  RepurchasePriceRatio:'買戻し価格比率',
  BuyQuantity:'購入数量',
  SellQuantity:'販売数量',
  placeholder1: '購入数量を入力してください',
  placeholder2: '販売数量を入力してください',
  submit:'送信',
  OTCwallet:'OTCウォレット',
  SellToOTCbalance:'OTC残高に売却',
  trxnumber:'TRX数',
  onSale:'オーダー',
  sellingScope:'販売範囲',
  hangingOrderPrice:'請求価格',
  surplus:'余剰',
  withdraw:'撤回',
  completed:'完了',
  withdrawn:'撤回されました',
  sellOut:'売り出す',
  purchase:'購入する',
  comfirmTips:'取り消しを確認しますか。',
  confirm:'確認',
  cancel:'キャンセル',
  unlimited:'制限なし'
}

const order ={
  orderHistory:'履歴',
  all: 'すべて',
  inProgress: '進行中',
  expired: '期限切れ',
  completed: '完了',
  orderID:'注文ID',
  Payable:'支払い',
  buy:'購入',
  viewNow:'今すぐ見る',
  PaymentDetails:'支払い詳細',
  PurchaseType:'購入タイプ',
  OrderTime:'購入時間',
  State:'状態'
}

const login = {
  TNFT_account_login:'TNFTアカウントログイン',
  phone:'電話番号',
  mail:'メール',
  pwd:'パスワード',
  placeholder1: '電話番号を入力してください',
  placeholder2: 'メールを入力してください',
  placeholder3: 'パスワードを入力してください',
  placeholder4: '確認コードを入力してください',
  placeholder5: '紹介コードを入力してください',
  forgotPwd: 'パスワードを忘れましたか？',
  forgotPwd1: 'パスワードを忘れた',
  Reset_password :'パスワードをリセット',
  login:'ログイン',
  Dont_have_an_account_yet: 'まだアカウントがありませんか？',
  Register_now: '今すぐ登録',
  Register:'登録',
  VerificationCode:'確認コード',
  ConfirmPassword:'パスワード確認',
  getCode:'確認コード',
  send:'送信済み',
  Referral_Code:'紹介コード',
  by:'登録をクリックすると、',
  User_Agreement:'利用規約に同意したことになります',
  submit: '確認'
}

const share = {
  My_invitation_code:'マイ招待コード',
  desc:'TNFTをスキャンしてプレイ、取引手数料ゼロ、さらに多くの紹介手数料',
  save:'QRコードを保存'
}

const my = {
  Merchant:'商人',
  OTCwallet:'OTCウォレット',
  myCollect: 'マイコレクション',
  myNFT:'マイNFT',
  Fragments: 'フラグメント',
  OTCbalance: 'OTC残高',
  Exchange:'ロイヤリティ交換',
  Transfer_to_NFT:'NFTに転送',
  Staking_TNFT: 'TNFTの質押',
  Account_binding: 'アカウントの紐付け',
  Invite_members: 'メンバーを招待',
  Contact_Customer_Service: 'カスタマーサービスに連絡',
  sign_out:'ログアウト',

  Apply_to_be_a_merchant:'商人になるために申し込む',
  Company_Name:'会社名',
  p_e_Company_Name:'会社名を入力してください',
  Country: '国',
  p_e_Country: '国を入力してください',
  Introduction: '紹介',
  p_e_Introduction: '紹介を入力してください',
  Business_license:'営業許可証',
  Front_side_of_legal_person_certificate:'法人証明書の表側',
  The_reverse_side_of_the_legal_person_certificate:'法人証明書の裏側',

  Apply_to_be_a_auth:'実名認証の申請',
  auth_realname:'名前',
  auth_idcard:'身分証明書番号',
  bank_name:'銀行名',
  bank_account:'銀行口座名',
  bank_card:'銀行口座',
  Front_side_of_certificate:'身分証明書の正面',
  The_reverse_side_of_certificate:'身分証明書の裏面',
  p_auth_realname: '名前を入力してください',
  p_auth_idcard: '身分証明書番号を入力してください',
  p_bank_name:'銀行名を入力してください',
  p_bank_account:'銀行口座名を入力してください',
  p_bank_card:'请输入银行帐号',

  Submit:'送信',
  reSubmit:'再送信',

  Edit_information: '情報を編集',
  avatar: 'アバター',
  Nickname: '本名',
  Account: 'アカウント',
  Referral_code: '紹介コード',
  Credit_score: '信用スコア',
  SecuritySettings: 'セキュリティ設定',
  Transaction_password: '取引パスワード',
  New_Transaction_password: '新しい取引パスワード',
  Login_password: 'ログインパスワード',
  New_Login_password: '新しいログインパスワード',
  ConfirmPassword: 'パスワード確認',
  p_e_Nickname: '名前を入力してください',
  p_e_Password: 'パスワードを入力してください',

  Redeem_NFT: 'NFTを交換',
  Synthesis: '合成',
  price: '価格',
  Number_of_holdings:'保有数量',
  Price_trend: '価格動向',
  Buy: '買う',
  Sell: '売る',
  OTCtrading:'OTC取引',
  OTC_quick_transaction:'OTC迅速取引',
  OTC_desc:'迅速取引により、現在のOTC市場の最適価格で取引を完了できます',
  I_want_to_pay: '支払いたい',
  I_will_receive: '受け取る',
  Proposed_price: '参考価格',
  How_to_use_OTC:'OTCの使い方',
  OTCdesc1: '1.デジタル通貨を購入する前に、OTCアカウントを登録する必要があります。提供されたメールアドレスまたは携帯電話で受け取った確認コードを使用してアカウントを確認するか、TNFTアカウントを直接使用して登録します。',
  OTCdesc2: '2.取引の安全性とコンプライアンスを確保するために、身分証明書を提出して身元確認を行ってください。プラットフォームは提供された身分情報を審査します。このプロセスには時間がかかる場合があるので、しばらくお待ちください。',
  OTCdesc3: '3.アカウント登録と身元確認が完了すると、デジタル通貨の購入を開始できます。購入ページに進み、購入するデジタル通貨を選択します。購入数量を入力して取引を確認します。取引を確認する前に、注文のすべての詳細を必ず確認してください。注文がマッチングされ、支払いが完了すると、デジタル通貨がOTCアカウントに転送されるのを待ちます。',

  OTCrecords:'OTC記録',
  Unit_price:'単価',
  Quantity:'数量',
  Approved:'承認済み',
  Cancelled:'キャンセル',
  NFT_transfer_record:'NFT転入記録',

  NFT_transfer_in:'NFT転入',
  Category:'カテゴリー',
  address: 'アドレス',
  p_e_address: 'アドレスを入力してください',
  NFTname:'NFT名',
  Features:'特徴',
  Description:'説明',
  p_e_name: '名前を入力してください',
  p_e_Features: '特徴を入力してください',
  p_e_Description: '説明を入力してください',
  Upload_NFT_file:'NFTファイルをアップロード',
  Upload_transfer_certificate:'譲渡証明書をアップロード',
  copy_success:'コピー成功',
  Confirm:'確認',
  Pledge: '質押',
  Hold: '保有',
  Pre_sale_price:"プレセール価格",
  Time_day:'時間（日）',
  Pledge_quantity:'質押数量',
  p_e_quantity:'数量を入力してください',
  Quantity_Available:'利用可能数量',
  all:'全部',
  Fragment_reward_days:'フラグメント報酬（日）',
  Remark:'備考',
  Pledge_time:'質押時間',
  Interest_accrual_time: '利息発生時間',
  Redemption_time: '償還時間',
  Award:'報酬',
  Creation_time:'作成時間',
  End_time:'終了時間',
  Unpledge:'質押解除',
  Team_list:'チームリスト',
  account:'アカウント',
  zctime:'登録時間',
  Royalty_Details:'ロイヤリティ詳細',
  income: '収入',
  spending: '支出',
  Bind_account:'アカウントの紐付け',
  Please_enter_the_transaction_password:'取引パスワードを入力してください',
  noSart:'一般会員には未開放',
  search:'検索',
  latest: '最新',
  hottest: '人気',
  p_e_search:'キーワードを入力してください',
  Application_Notes:'申請注意事項',
  issuer_desc:'NFT発行者になるためには、適切なブロックチェーンの法的資格が必要です。詳細は公式カスタマーサービスまたはメールでお問い合わせください',
  start:'開始',

  Make_NFT_fragments:'NFTフラグメントを作成',
  NFTfile:'NFTファイル',
  Purchase_limit: '購入制限',
  Purchase_limit_desc: '一人当たりの購入制限',
  Currency: '通貨',
  Buyback_price: '買戻し価格',
  countDown: 'カウントダウン（時間）',
  Transaction_object_ID: '取引対象ID',
  Click_to_select_NFT_file: 'NFTファイルを選択',
  Click_to_select_Currency: '通貨を選択',
  Click_to_select_countDown: 'カウントダウン時間を選択',
  p_e_price: '価格を入力してください',
  create_now:'今すぐ作成',
  Release_record:'リリース記録'
}

const nft = {
  collect:"コレクション",
  transfer:"NFT転送",
  pledgeRecord:"フラグメント質押記録",
  hangout:'注文掲示',
  buytitle:'購入したい',
  buy:'購入',
  sell:'販売',
  selltitle:'販売したい',
  listcount:'注文数量',
  owner:'所有者',
  between:'範囲',
  remains:'残り',
  hangoutlabel:['現在保有','NFT総数','タイプ','プレセール価格',
    '価格','数量','1回の購入制限','最高','売却','購入'],
  hangplaceholder:['注文価格を入力してください','数量を入力してください',
  '','最高数量を入力してください'],
  holdnumber:'保有数量',
  available_number:'使用可能な数量',
}

const otc = {
  orderID: "注文ID",
  amount: "金額",
  addtime: "時間",
  detailtitle: "取引情報",
  logintips: [
    "お客様の情報は256ビットSGCによって保護されています",
    "暗号化を使用して安全に伝送されます"
  ],
  detail: ["価格", "取引数量", "支払金額", "注文ID", "時間", "カスタマーサービスにお問い合わせください"]
}

const onekey = {
  royalties: "ロイヤリティ",
  hold: "保有数量",
  all: "全部",
  selltips: [
    "NFTロイヤリティは、あなたの努力から利益を得るためのシンプルで簡単な方法です。",
    "NFTロイヤリティは、アーティスト、ゲーム開発者、コンテンツクリエイターにとって、これまでにない方法で二次販売から利益を得る素晴らしい機会です。",
    "TNFTロイヤリティ取引エクスプレスを通じて、ロイヤリティの売買から利益を得ることができます。"
  ],
}

const order2 = {
  presale: "先行販売",
  otc: "OTC残高",
  pay: "支払い",
  c2c: "C2C振込",
  waitconfirm: "審査待ち",
  pass: "承認済み",
  c2ctip: "C2Cは手動で振込が必要です",
  status: ["", "支払い待ち", "確認待ち", "完了", "に失敗"],
  confirm: ["注文を確認", "先行販売中、注文完了後、規定時間内に販売者へ支払ってください"],
  c2cpay: ["対応通貨", "住所", "支払金額", "証明書をアップロード"],
  position:['保有数量'],
  otctip:'OTC-payはあなたの残高を使用して支払います。確認してください',
  missingfund:'取引パスワードがありません',
  setfundtip:'まだ取引パスワードを設定していません。先に取引パスワードを設定してください。'

}


export {
  tab,
  index,
  find,
  position,
  order,
  login,
  share,
  my,
  nft,
  otc,
  onekey,
  order2
}
